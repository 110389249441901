import React from "react";
import {formatTextWithLineBreaks} from "../utils/helper";
export const Downloads = (props) => {

    const DOWNLOAD_LINKS = [
        {
            id: 'google',
            url: 'https://play.google.com/store/apps/details?id=com.example',
            iconClass: 'fab fa-google-play fa-2x',
        },
        {
            id: 'apple',
            url: 'https://apps.apple.com/app/id1234567890',
            iconClass: 'fab fa-apple fa-2x',
        },
        {
            id: 'windows',
            url: 'https://www.microsoft.com/store/apps/9NBLGGH4NNS1',
            iconClass: 'fab fa-windows fa-2x',
        },
    ];

    const handleClick = (url) => {
        window.location.href = url;
    };

  return (
    <div id="downloads" className="text-center">
        <div className="container-fluid">
            <div className="play-now">
                {props.data ? (
                    <>
                        <img src={props.data.playnow} alt="Logo"/>
                    </>
                ) : null}
            </div>
            <div className="col-12 d-flex justify-content-center download-buttons-container">

                {DOWNLOAD_LINKS.map((link) => (
                    <button
                        key={link.id}
                        className="download-button"
                        onClick={() => handleClick(link.url)}
                        style={{ cursor: 'pointer' }}
                    >
                        <i className={link.iconClass}></i>
                        <span>{props.data ? formatTextWithLineBreaks(props.data[link.id]) : 'loading...'}</span>
                    </button>
                ))}
            </div>
        </div>
    </div>
  );
};
