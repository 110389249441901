import React, { useState } from 'react';

import languageData from '../data/data.json';
import {Dropdown} from "primereact/dropdown";

import 'primeflex/primeflex.css';



const LanguageSelector = ({ onLanguageChange }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const selectedLanguageTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img
                        alt={option.name}
                        src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
                        className={`mr-2 flag flag-${option.code.toLowerCase()}`}
                        style={{ width: '18px' }}
                    />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const languageOptionTemplate = (option) => {
        console.log(option);
        return (
            <div className="flex align-items-center">
                <img
                    alt={option.name}
                    src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`}
                    className={`mr-2 flag flag-${option.code.toLowerCase()}`}
                    style={{ width: '18px' }}
                />
                <div>{option.name}</div>
            </div>
        );
    };

    const handleChange = (e) => {
        setSelectedLanguage(e.value);
        onLanguageChange(e.value); // Pasa el objeto de opción completo
    };

    return (
        <Dropdown
            value={selectedLanguage}
            onChange={handleChange}
            options={languageData.languages}
            optionLabel="name"
            placeholder="Select a Language"
            valueTemplate={selectedLanguageTemplate}
            itemTemplate={languageOptionTemplate}
            className="w-full md:w-14rem"
        />
    );
};

export default LanguageSelector;
