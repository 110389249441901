import ReactPlayer from 'react-player'
import React from "react";

export const Trailer = (props) => {
  return (
      <div id="trailer">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-8">
              <div className="header-section">
                <h2>{props.data ? props.data.title : "loading..."}</h2>
              </div>
                <div className="video d-flex justify-content-center">
                    <ReactPlayer url='https://www.youtube.com/watch?v=upOKh1TVb3o' />
                </div>
            </div>
          </div>
        </div>
      </div>
  );
};
