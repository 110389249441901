import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Downloads } from "./components/downloads";
import { About } from "./components/about";
import { Trailer } from "./components/trailer";
import { Seasons } from "./components/seasons";
import { Streamers } from "./components/streamers";
import { Support } from "./components/support";
import languageData from './data/data.json'; // Archivo con la lista de idiomas
import SmoothScroll from "smooth-scroll";
import "./App.css";
import {Footer} from "./components/footer";
import LanguageSelector from "./components/LanguageSelector";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [language, setLanguage] = useState('data_en.json'); // Idioma por defecto

    useEffect(() => {
        fetchLanguageData(language);
    }, [language]);

    const fetchLanguageData = async (file) => {
        try {
            const data = await import(`./data/${file}`);
            setLandingPageData(data.default);
        } catch (error) {
            console.error('Error loading language data:', error);
        }
    };

    const handleLanguageChange = (event) => {

        setLanguage(event.file);
    };

  return (
    <div>
      <Navigation data={landingPageData.Menu}/>
      <Header data={landingPageData.Header} />
      <Downloads data={landingPageData.Downloads} />
      <About data={landingPageData.About} />
      <Trailer data={landingPageData.Trailer} />
      <Streamers data={landingPageData.Streamers} />
        <Support data={landingPageData.Support} />
        <Footer data={landingPageData.Footer}>
            <LanguageSelector onLanguageChange={handleLanguageChange} />
        </Footer>
    </div>
  );
};

export default App;
