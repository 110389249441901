import React from "react";
import { formatTextWithLineBreaks } from '../utils/helper';
export const Streamers = (props) => {
  return (
      <div id="streamers">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-8">
                      <div className="header-section ">
                          <h2>{props.data ? props.data.title : "loading..."}</h2>
                      </div>
                      <div>
                          {props.data ? formatTextWithLineBreaks(props.data.text) : "loading..."}
                      </div>
                      <div className="margintop-20">
                          <button
                              className="generic-button">{props.data ? formatTextWithLineBreaks(props.data.button_text) : "loading..."}</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};
