import React from "react";
import {formatTextWithLineBreaks} from "../utils/helper";

export const Support = (props) => {
  return (
      <div id="support">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-8">
                      <div className="about-text header-section text-center">
                          <h2>{props.data ? props.data.title : "loading..."}</h2>

                          <p>{props.data ? formatTextWithLineBreaks(props.data.text) : "loading..."}</p>
                          <button className="generic-button">{props.data ? formatTextWithLineBreaks(props.data.button_text) : "loading..."}</button>

                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};
